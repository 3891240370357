<template>
  <div class="app bg-gray_fifth text-base flex flex-col m-auto w-full min-h-full">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
});
</script>

<style scoped>
.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/require-scoped -->
<style>
html,
body,
#app {
  margin: 0;
  height: 100%;
}
</style>
