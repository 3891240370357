import 'windi.css';
import '@fontsource/montserrat';

import { install as FrontendCorePlugin, vuetify } from '@anschuetz-elog/frontend-core';
import Vue, { VNode } from 'vue';

import App from '~/App.vue';
import useFeathers, { connect as connectFeathers } from '~/compositions/useFeathers';
import router from '~/router';

useFeathers();
connectFeathers();

const vueOptions = {
  router,
  parentRouteName: 'home',
};
Vue.use(FrontendCorePlugin, vueOptions);

vuetify.framework.theme.dark = false;

new Vue({
  ...vueOptions,
  render: (h): VNode => h(App),
}).$mount('#app');
