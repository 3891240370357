<template>
  <Box title-centered :title="$tc('report.noon_report.passed_time_zones')">
    <v-container fluid class="pa-0">
      <v-row no-gutters justify="start">
        <v-col class="py-1">
          <div v-if="loadingPreviousReports || timeZoneProtocolsLoading" class="d-flex flex-column">
            <v-progress-circular class="mx-auto my-2" color="primary" indeterminate />
          </div>
          <template v-else-if="timeZones.length > 0">
            <DetailsListEntry
              v-for="(timeZone, index) in timeZones"
              :key="index"
              :title="$tc('report.noon_report.entered_time_zone', undefined, { timestamp: timeZone.timestamp })"
              :content="getTimeZone(timeZone.timeZone)"
            />
          </template>
          <ProtocolEntry v-else class="gray_first--text mt-4">{{
            $tc('report.noon_report.no_passed_time_zones')
          }}</ProtocolEntry>
        </v-col>
      </v-row>
    </v-container>
  </Box>
</template>

<script lang="ts">
import { FIXED_PROTOCOL_TYPE_IDS, Protocol, TimeZone, timeZoneContentOptions } from '@anschuetz-elog/common';
import { orderBy } from 'lodash';
import moment from 'moment';
import { computed, defineComponent, PropType, ref, toRef, watch } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import Box from '#/components/layout/Box.vue';
import ProtocolEntry from '#/components/ProtocolEntry.vue';
import useFeathers from '#/compositions/useFeathers';
import useFind from '#/compositions/useFind';
import { loadProtocols } from '#/compositions/useProtocols';
import { getLatestObjectsWithHistory, loadSuccessorsAndPredecessors } from '#/lib/history';
import { getValidVoyage } from '#/lib/voyages';
import { dateTimeStr } from '#/utilities';

export default defineComponent({
  name: 'TimeZoneContentBox',
  components: { Box, DetailsListEntry, ProtocolEntry },
  props: {
    report: {
      type: Object as PropType<Protocol>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
  },
  setup(props) {
    const feathers = useFeathers();
    const report = toRef(props, 'report');

    const { data: previousReportsConfig, isLoading: isLoadingPreviousReports } = loadProtocols(
      computed(() => ('_id' in report.value.type ? report.value.type._id : report.value.type.ref._id)),
    );
    const previousReports = ref<Protocol[]>([]);
    const loadingPreviousReports = ref<boolean>(false);
    const timeZoneProtocols = ref<Protocol[]>([]);
    const timeZoneProtocolsLoading = ref<boolean>(false);
    const { data: voyages } = useFind('voyage');

    watch(previousReportsConfig, () => {
      loadingPreviousReports.value = isLoadingPreviousReports.value;
      previousReports.value = [];
      if (!isLoadingPreviousReports.value) {
        void loadPreviousReports(previousReportsConfig.value);
      }
    });

    async function loadPreviousReports(reports: Protocol[]) {
      loadingPreviousReports.value = true;
      const loadedReports = await loadSuccessorsAndPredecessors(reports, feathers.service('protocol'));
      loadingPreviousReports.value = false;
      previousReports.value = loadedReports;
    }

    const latestPreviousReports = computed(() => {
      const latestReports = getLatestObjectsWithHistory(previousReports.value).map((reportHistory) => {
        return reportHistory.data;
      });
      return latestReports;
    });

    const previousReport = computed(() => {
      const referenceTimestamp = moment(report.value.timestamp);
      const nonDeletedReports = latestPreviousReports.value.filter(
        (otherReport) =>
          otherReport._id !== report.value._id &&
          !otherReport.deleted &&
          referenceTimestamp.isAfter(otherReport.timestamp),
      );
      const nonDeletedReportsSorted = orderBy(nonDeletedReports, (report) => moment(report.timestamp), ['asc']);

      const previousReportIndex = nonDeletedReportsSorted.length - 1;
      return previousReportIndex >= 0 ? nonDeletedReportsSorted[previousReportIndex] : null;
    });

    const validVoyage = computed(() => {
      return getValidVoyage(voyages.value, report.value.timestamp);
    });

    const reportValidity = computed(() => {
      if (!previousReportsConfig.value || isLoadingPreviousReports.value) {
        return null;
      }
      let start: moment.Moment;
      if (previousReport.value) {
        start = moment(previousReport.value.timestamp);
      } else if (validVoyage.value) {
        start = moment(validVoyage.value.startTime);
      } else {
        return null;
      }
      return {
        start,
        end: moment(report.value.timestamp),
      };
    });

    const { data: timeZoneProtocolsConfig, isLoading: isLoadingTimeZoneProtocols } = loadProtocols(
      computed(() => (!reportValidity.value ? undefined : FIXED_PROTOCOL_TYPE_IDS.TIME_ZONE)),
    );

    watch(timeZoneProtocolsConfig, () => {
      timeZoneProtocolsLoading.value = isLoadingTimeZoneProtocols.value;
      timeZoneProtocols.value = [];
      if (!isLoadingTimeZoneProtocols.value) {
        void loadTimeZoneProtocols(timeZoneProtocolsConfig.value);
      }
    });

    async function loadTimeZoneProtocols(protocols: Protocol[]) {
      const newTimeZoneProtocols = protocols.filter((protocol) => {
        // this filtering is redundant (see query of #linkingProtocolsConfig) but necessary for online only mode
        // TODO: remove this filtering when gateway supports querying for it
        return (
          ('_id' in protocol.type ? protocol.type._id : protocol.type.ref._id) === FIXED_PROTOCOL_TYPE_IDS.TIME_ZONE
        );
      });
      timeZoneProtocolsLoading.value = true;
      const loadedProtocols = await loadSuccessorsAndPredecessors(newTimeZoneProtocols, feathers.service('protocol'));
      timeZoneProtocolsLoading.value = false;
      timeZoneProtocols.value = loadedProtocols;
    }

    const timeZones = computed(() => {
      const timeZoneRecords = getLatestObjectsWithHistory(timeZoneProtocols.value).map((recordHistory) => {
        return recordHistory.data;
      });
      const nonDeletedTimeZoneRecords = timeZoneRecords.filter(
        (record) =>
          !record.deleted &&
          reportValidity.value &&
          moment(record.timestamp).isBetween(reportValidity.value.start, reportValidity.value.end, undefined, '[]'),
      );
      const nonDeletedTimeZoneRecordsSorted = orderBy(nonDeletedTimeZoneRecords, (record) => moment(record.timestamp), [
        'asc',
      ]);

      const timeZoneRecordsFiltered = nonDeletedTimeZoneRecordsSorted.map((record) => ({
        timestamp: dateTimeStr(record.timestamp),
        timeZone: (record.contents[0].data as TimeZone).tz?.toString(),
      }));
      return timeZoneRecordsFiltered;
    });

    function getTimeZone(timeZoneValue: string | undefined): string {
      if (!timeZoneValue) {
        return '';
      }
      const timeZone = timeZoneContentOptions.find((option) => option.value === timeZoneValue);

      return timeZone ? timeZone.text : '';
    }

    return {
      loadingPreviousReports,
      timeZoneProtocolsLoading,
      timeZones,
      getTimeZone,
    };
  },
});
</script>
