import { Book, FlagState, SystemInfo } from '@anschuetz-elog/common';
import { sortBy } from 'lodash';
import moment from 'moment';
import { computed, Ref, ref } from 'vue';

import i18n from '#/i18n';
import { dateStr } from '#/utilities';

import useGet from './useGet';

type LicenseName = 'FLAG_LIBERIA' | 'NOON_REPORT' | 'WATCH_ORDERS' | Book['technicalName'];
type Licenses = { feature: string; featureLabel: string; validTo: string; valid: boolean }[];

/** get all licenses */
let licenses: Ref<Licenses> | undefined = undefined;

export function getLicenses(): Ref<Licenses> {
  if (!licenses) {
    const { data: systemInfo } = useGet('systemInfo', ref(SystemInfo.FixedId));
    licenses = computed(() => {
      if (!systemInfo.value || !systemInfo.value.gatewayInfo) {
        return [];
      }

      const licenses = systemInfo.value.gatewayInfo.licenses.map((license) => {
        return {
          ...license,
          // need to fallback to license.feature for featureLabel and feature because for E00.11 we had to go with old license format
          // where license.feature is actually license.featureLabel and we don't have a technical license name (see #oldBookLicenseMap as well)
          featureLabel: license.featureLabel || license.feature,
          feature: license.feature.split('.')[1] || license.feature,
          validTo: license.valid
            ? license.validTo
              ? dateStr(license.validTo)
              : i18n.tc('about.valid_forever')
            : i18n.tc('about.license_invalid'),
          // we map validity to expired check here (a license is expired if we have a validTo date and this date is in the past)
          valid: license.valid && !(license.validTo && moment(license.validTo).isBefore()),
        };
      });

      return sortBy(licenses, ['featureLabel']);
    });
  }
  return licenses;
}

const bookLicenseMap: Record<LicenseName, string> = {
  BallastWaterRecordBook: 'BALLAST_WATER_RECORD_BOOK',
  bellBook: 'BELLBOOK',
  BioFoulingRecordBook: 'BIOFOULING_RECORD_BOOK',
  BunkSamp: 'BUNKER_LOGBOOK',
  CRB: 'CARGO_RECORD_BOOK',
  deckLogbook: 'DECK_LOGBOOK',
  ChronometerRateBook: 'CHRONOMETER_RATE_BOOK',
  CompassObservationBook: 'COMPASS_OBSERVATION_BOOK',
  ControlledDrugsRegister: 'DRUGS_REGISTER',
  EngineLogbook: 'ENGINE_LOGBOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FLAG_LIBERIA: 'FLAG_LIBERIA',
  GarbageRecordBookPartI: 'GARBAGE_RECORD_BOOK_I',
  GarbageRecordBookPart2: 'GARBAGE_RECORD_BOOK_II',
  HarbourTowage: 'HARBOUR_TOWAGE',
  KeyRegisterBook: 'KEY_REGISTER_BOOK',
  LiftingAppliancesRecordBook: 'REGISTER_OF_LIFTING_APPLIANCES',
  MedicalLogbook: 'MEDICAL_LOGBOOK',
  MLCInspections: 'MLC_RECORD_BOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOON_REPORT: 'NOON_REPORT',
  ODSRecB: 'ODS_RECORD_BOOK',
  OilRecordBookPartI: 'OIL_RECORD_BOOK_I',
  ORBII: 'OIL_RECORD_BOOK_II',
  RadioLogbook: 'RADIO_LOGBOOK',
  RadarLogbook: 'RADAR_LOGBOOK',
  SealRecordBook: 'SEAL_RECORD_BOOK',
  SewageAndGraywaterRecordBook: 'SEWAGE_RECORD_BOOK',
  ShipSecurityLogbook: 'SHIP_SECURITY_LOGBOOK',
  SoundingBookDeck: 'SOUNDING_BOOK',
  SoundingBookEngine: 'SOUNDING_BOOK',
  tanks: 'TANKS',
  VisitorLogbook: 'VISITOR_LOGBOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WATCH_ORDERS: 'WATCH_ORDERS',
};

/** @deprecated old book license map necessary for E00.11 */
const oldBookLicenseMap: Record<LicenseName, string> = {
  BallastWaterRecordBook: 'Ballast water record book',
  bellBook: 'Bellbook',
  BioFoulingRecordBook: 'Biofouling record book',
  BunkSamp: 'Bunker logbook',
  CRB: 'Cargo record book',
  deckLogbook: 'Deck logbook',
  ChronometerRateBook: 'Chronometer rate book',
  CompassObservationBook: 'Compass observation book',
  ControlledDrugsRegister: 'Controlled drugs register',
  EngineLogbook: 'Engine logbook',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FLAG_LIBERIA: 'FLAG_LIBERIA',
  GarbageRecordBookPartI: 'Garbage record book part I',
  GarbageRecordBookPart2: 'Garbage record book part II',
  HarbourTowage: 'Harbour towage',
  KeyRegisterBook: 'Key register book',
  LiftingAppliancesRecordBook: 'Register of lifting appliances',
  MedicalLogbook: 'Medical logbook',
  MLCInspections: 'MLC record book',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOON_REPORT: 'Noon report',
  ODSRecB: 'ODS and F-Gas record book',
  OilRecordBookPartI: 'Oil record book part I',
  ORBII: 'Oil record book part II',
  RadioLogbook: 'Radio logbook',
  RadarLogbook: 'Radar logbook',
  SealRecordBook: 'Seal record book',
  // cspell:words graywater
  SewageAndGraywaterRecordBook: 'Sewage and graywater discharge record book',
  ShipSecurityLogbook: 'Ship security logbook',
  SoundingBookDeck: 'Sounding book',
  SoundingBookEngine: 'Sounding book',
  tanks: 'Tanks',
  VisitorLogbook: 'Visitor logbook',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WATCH_ORDERS: 'Watch orders',
};

export function isLicenseValid(licenseName: LicenseName, licenses: Licenses): boolean {
  return licenses.some(
    (license) =>
      license.valid &&
      (license.feature === bookLicenseMap[licenseName] ||
        // fallback for E00.11 due to old license format
        license.feature.toLowerCase() === oldBookLicenseMap[licenseName]?.toLowerCase()),
  );
}

export function checkLiberiaFlagState(flagStates: Ref<FlagState[]>): {
  invalidFlagStates: Ref<Record<FlagState['_id'], boolean>>;
  someInvalid: Ref<boolean>;
} {
  const licenses = getLicenses();

  function isFlagStateValid(flagState: FlagState) {
    if (isLicenseValid('FLAG_LIBERIA', licenses.value)) {
      return true;
    }
    const isLiberia = /l\s*i\s*b\s*e\s*r\s*i\s*a/i.test(flagState.flagState);
    if (isLiberia) {
      return (
        flagState.ceaseDateOfFlagstateRegistration !== undefined &&
        moment(flagState.ceaseDateOfFlagstateRegistration).isSameOrBefore(undefined, 'date')
      );
    }
    return true;
  }

  const invalidFlagStates = computed(() => {
    return flagStates.value.reduce<Record<FlagState['_id'], boolean>>(
      (dict, flagState) => ({
        ...dict,
        [flagState._id]: !isFlagStateValid(flagState),
      }),
      {},
    );
  });

  const someInvalid = computed(() => {
    return Object.values(invalidFlagStates.value).some((invalid) => invalid);
  });

  return { invalidFlagStates, someInvalid };
}
