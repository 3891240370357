<template>
  <ProtocolEntry :data-test="dataTest">
    <div class="details-list-entry">
      <span class="listTitle" :title="titleHover">{{ title }}</span>
      <div class="contentWrapper" :class="customContentStyles" :data-test="`${dataTest}-details`">
        <v-progress-circular v-if="loading" class="mx-auto gray_first--text" indeterminate size="24" />
        <template v-else-if="markAsUnknown">
          <Icon class="unknownPropIcon" name="symbol/warningMarker" color="warning" />
          <span :class="{ listContent: true, [`${color}--text`]: true }">{{ $t('missing_value') }}</span>
        </template>
        <span v-else :class="{ listContent: true, [`${color}--text`]: true }">{{ contentString }}</span>
      </div>
    </div>
  </ProtocolEntry>
</template>

<script lang="ts">
import { ContentOption } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from './Icon.vue';
import ProtocolEntry from './ProtocolEntry.vue';

export default defineComponent({
  name: 'DetailsListEntry',
  components: {
    Icon,
    ProtocolEntry,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleHover: {
      type: String,
      default: undefined,
    },
    suffix: {
      type: String,
      default: '',
    },
    content: {
      type: [String, Number, Boolean, Array] as PropType<string | number | boolean | (string | number | boolean)[]>,
      default: '',
    },
    markAsUnknown: {
      type: Boolean,
    },
    contentOptions: {
      type: Array as PropType<ContentOption[] | null>,
      default: null,
    },
    color: {
      type: String,
      default: 'gray_first',
    },
    loading: {
      type: Boolean,
    },
    customContentStyles: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    dataTest: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const contentOptions = toRef(props, 'contentOptions');
    const content = toRef(props, 'content');
    const suffix = toRef(props, 'suffix');

    const contentString = computed(() => {
      if (contentOptions.value) {
        const cont = contentOptions.value.find((option) => option.value === content.value);
        return cont ? cont.text : '';
      } else if (content.value === '') {
        return '';
      }
      return suffix ? content.value.toString().concat(' ' + suffix.value) : content.value;
    });

    return {
      contentString,
    };
  },
});
</script>

<style scoped>
.listTitle {
  font-weight: 600;
  color: var(--v-gray_second-base);
  font-size: 10px;
  letter-spacing: 0.2px;
  margin-bottom: 6px;
}

.listContent {
  font-size: 16px;
}

.unknownPropIcon {
  width: 24px;
}

.contentWrapper {
  display: flex;
  min-height: 24px;
}
</style>
